@import 'src/styles/vars';
@import 'src/styles/flexbox';

.numbers {
    padding: 85px 0 0;
    // @include bigDesktop {
    //     padding: 170px 0 0;
    // }
    // @include laptop {
    //     padding: 85px 0 0;
    // }
    @include mobile {
        padding: 60px 0 0;
    }
    .wrapper {
        padding: 0 59px;
        max-width: 1163px;
        // @include bigDesktop {
        //     padding: 0 20px;
        //     max-width: 2112px;
        // }
        // @include laptop {
        //     padding: 0 59px;
        // }
        @include mobile {
            padding: 0 24px;
        }
    }
    .blockLabel {
        margin-left: 20px;
        // @include laptop {
        //     margin-left: 20px;
        // }
        @include mobile {
            margin: 0;
        }
    }
}

.list {
    margin-top: 32px;
    @extend .box-row;
    @include noMobile {
        border-left: 1px solid $grayColor3;
    }
    // @include laptop {
    //     margin-top: 32px;
    // }
    @include mobile {
        flex-direction: column;
        margin-top: 16px;
        row-gap: 16px;
    }
}

.item {
    @extend .box-col;
    @include noMobile {
        border-right: 1px solid $grayColor3;
        flex: 0 0 calc(100% / 3);
    }
    @include mobile {
        row-gap: 8px;
    }
}

.top {
    @extend .box-col;
    row-gap: 2px;
    padding: 0 24px 15px;
    @include noMobile {
        border-bottom: 1px solid $grayColor3;
    }
    // @include bigDesktop {
    //     row-gap: 0;
    //     padding: 52px 40px 0;
    // }
    // @include laptop {
    //     row-gap: 2px;
    //     padding: 0 24px 15px;
    // }
    @include mobile {
        padding: 0;
        row-gap: 4px;
    }
}

.label {
    line-height: 21px;
    flex: 0 0 21px;
    // @include bigDesktop {
    //     line-height: 36px;
    //     flex: 0 0 36px;
    // }
    // @include laptop {
    //     line-height: 21px;
    //     flex: 0 0 21px;
    // }
    @include mobile {
        line-height: 18px;
        font-family: $fontVisueltLight;
        &:empty {
            display: none;
        }
    }
}

.count {
    font-size: 80px;
    line-height: 103px;
    color: $greenColor;
    font-family: $fontVisueltMedium;
    // @include bigDesktop {
    //     font-size: 156px;
    //     line-height: 200px;
    // }
    // @include laptop {
    //     font-size: 80px;
    //     line-height: 103px;
    // }
    @include mobile {
        font-size: 48px;
        line-height: 56px;
    }
}

.desc {
    font-size: 32px;
    line-height: 40px;
    padding: 24px;
    // @include bigDesktop {
    //     font-size: 56px;
    //     line-height: 64px;
    //     padding: 32px 40px;
    // }
    // @include laptop {
    //     font-size: 32px;
    //     line-height: 40px;
    //     padding: 24px;
    // }
    @include mobile {
        padding: 0;
        font-size: 20px;
        line-height: 28px;
    }
}
