@import 'src/styles/vars';

.label {
    position: relative;
    font-size: 32px;
    line-height: 40px;
    font-family: $fontVisueltLight;
    // @include bigDesktop {
    //     font-size: 54px;
    // }
    // @include laptop {
    //     font-size: 32px;
    //     line-height: 40px;
    // }
    @include mobile {
        font-size: 18px;
        line-height: 24px;
    }
    &:before {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $greenColor;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -20px;
        // @include bigDesktop {
        //     width: 10px;
        //     height: 10px;
        //     left: -38px;
        // }
        // @include laptop {
        //     width: 6px;
        //     height: 6px;
        //     left: -20px;
        // }
        @include mobile {
            width: 4px;
            height: 4px;
            left: -15px;
        }
    }
}