@import 'src/styles/vars';
@import 'src/styles/flexbox';

.topService {
    height: calc(100vh - 85px);
    // min-height: 952px;
    min-height: 634px;
    position: relative;
    background-color: $lightColor;
    color: $darkColor;
    @include noMobile {
        &:before, &:after {
            content: '';
            position: absolute;
            right: 0;
            width: 8px;
            height: 96px;
            transform: translateY(-180px);
            // @include laptop {
            //     width: 8px;
            //     height: 96px;
            // }
        }
        &:before {
            top: 50%;
            background-color: $greenColor;
            // @include laptop {
            //     top: 300px;
            // }
        }
        &:after {
            top: calc(50% + 96px);
            background-color: $grayColor2;
            // @include laptop {
            //     top: 396px;
            // }
        }
    }
    // @include laptop {
    //     min-height: 634px;
    // }
    @include mobile {
        height: 290px;
        min-height: 290px;
    }
}

.wrapper {
    @extend .box-col;
    @extend .space-center;
    @extend .align-start;
    row-gap: 117px;
    height: 100%;
    // @include laptop {
    //     padding: 240px 0 0;
    //     row-gap: 117px;
    // }
    @include mobile {
        row-gap: 15px;
        padding: 0;
        height: 100%;
    }
}

.title {
    font-family: $fontVisueltLight;
    font-size: 90px;
    line-height: 107px;
    // @include laptop {
    //     font-size: 90px;
    //     line-height: 107px;
    // }
    @include mobile {
        font-size: 32px;
        line-height: 40px;
    }
    svg {
        margin: 0 21px;
        vertical-align: sub;
        width: 109px;
        height: 117px;
        // @include laptop {
        //     margin: 0 21px;
        //     width: 109px;
        //     height: 117px;
        // }
        @include mobile {
            width: 41px;
            height: 44px;
            margin: 0 10px;
        }
    }
}

.bolder {
    font-family: $fontVisueltMedium;
    color: white;
    background-color: $greenColor2;
    margin-left: 21px;
    padding: 3px 23px;
    // @include laptop {
    //     margin-left: 21px;
    //     padding: 3px 23px;
    // }
    @include mobile {
        padding: 3px 10px;
        margin-left: 10px;
    }
}

.mobileLines {
    position: absolute;
    right: 0;
    width: 4px;
    height: 39px;
    background-color: $darkColor;
    top: 120px;
    &:before, &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
    }
    &:before {
        top: -39px;
        background-color: $greenColor;
    }
    &:after {
        bottom: -39px;
        background-color: $grayColor7;
    }
}