@import 'src/styles/vars';
@import 'src/styles/flexbox';

.forWhom {
    padding: 85px 0 0;
    // @include bigDesktop {
    //     padding: 170px 0 0;
    // }
    // @include laptop {
    //     padding: 85px 0 0;
    // }
    @include mobile {
        padding: 60px 0 0;
    }
}

.content {
    margin-top: 32px;
    @extend .box-row;
    @extend .space-between;
    // @include bigDesktop {
    //     margin-top: 96px;
    // }
    // @include laptop {
    //     margin-top: 32px;
    // }
    @include mobile {
        margin-top: 16px;
        flex-direction: column;
        justify-content: start;
        row-gap: 39px;
    }
}

.dropdown {
    @extend .box-col;
    flex: 0 0 calc(50% - 21px);
    row-gap: 70px;
    &:first-child svg {
        width: 176px;
        height: 153px;
    }
    &:last-child svg {
        width: 153px;
        height: 153px;
    }
    // @include bigDesktop {
    //     row-gap: 82px;
    //     flex: 0 0 calc(50% - 40px);
    //     &:first-child svg {
    //         width: 320px;
    //         height: 280px;
    //     }
    //     &:last-child svg {
    //         width: 280px;
    //         height: 280px;
    //     }
    // }
    // @include laptop {
    //     flex: 0 0 calc(50% - 21px);
    //     row-gap: 70px;
    //     &:first-child svg {
    //         width: 176px;
    //         height: 153px;
    //     }
    //     &:last-child svg {
    //         width: 153px;
    //         height: 153px;
    //     }
    // }
    @include mobile {
        flex: 0 0 auto;
        row-gap: 19px;
        &:first-child svg {
            width: 130px;
            height: 110px;
        }
        &:last-child svg {
            width: 130px;
            height: 130px;
        }
    }
}