// colors
$darkColor: #121212;
$lightColor: #E3E3E3;
$lightColor2: #BBB6B1;
$greenColor: #3BE65A;
$greenColor2: #14CC35;
$grayColor: #333333;
$grayColor2: #3A3A3A;
$grayColor3: #747474;
$grayColor4: #484848;
$grayColor5: #CBCBCB;
$grayColor6: #737373;
$grayColor7: #B7B7B7;

// fonts
$fontVisuelt: 'Visuelt Pro';
$fontVisueltLight: 'Visuelt Pro Light';
$fontVisueltMedium: 'Visuelt Pro Medium';
$fontIosevka: 'Iosevka Term SS17 Web';

// responsive
$bigDesktopWidth: 1921px;
$laptopWidth: 1704px;
$mobileWidth: 1279px;
$noMobileWidth: 1280px;
// $mobileWidth: 1155px;
// $noMobileWidth: 1156px;

@mixin bigDesktop {
    @media (min-width: $bigDesktopWidth) {
        @content;
    }
}

@mixin laptop {
    @media (max-width: $laptopWidth) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: $mobileWidth) {
        @content;
    }
}

@mixin noMobile {
    @media (min-width: $noMobileWidth) {
        @content;
    }
}


