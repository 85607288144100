@import 'src/styles/vars';
@import 'src/styles/flexbox';

.dropdown {
    @extend .box-col;
}

.labelButton {
    cursor: pointer;
    @extend .box-row;
    @extend .align-center;
    @extend .space-between;
    font-size: 40px;
    color: white;
    line-height: 51px;
    padding-bottom: 11px;
    border-bottom: 1px solid white;
    &:after {
        content: '';
        clip-path: polygon(100% 0, 0 0, 45% 100%);
        width: 25px;
        height: 20px;
        background-color: white;
        transition: transform 100ms;
        // @include laptop {
        //     width: 25px;
        //     height: 20px;
        // }
        @include mobile {
            width: 14px;
            height: 10px;
        }
    }
    // @include bigDesktop {
    //     font-size: 80px;
    // }
    // @include laptop {
    //     font-size: 40px;
    //     line-height: 51px;
    //     padding-bottom: 11px;
    // }
    @include mobile {
        font-size: 20px;
        line-height: 28px;
        padding-bottom: 8px;
    }
    &.open {
        color: $greenColor;
        &:after {
            background-color: $greenColor;
            transform: rotate(-180deg);
        }
    }
}

.content.content {
    @extend .box-col;
    row-gap: 24px;
    animation: fadeInContent 500ms forwards;
    // @include bigDesktop {
    //     row-gap: 60px;
    // }
    // @include laptop {
    //     row-gap: 24px;
    // }
    @include mobile {
        row-gap: 18px;
        animation: fadeInContentMobile 500ms forwards;
    }
}

.list {
    padding-top: 33px;
    @extend .box-col;
    row-gap: 16px;
    // @include bigDesktop {
    //     row-gap: 38px;
    //     padding-top: 54px;
    // }
    // @include laptop {
    //     padding-top: 33px;
    //     row-gap: 16px;
    // }
    @include mobile {
        padding-top: 16px;
    }
}

.item {
    font-family: $fontVisueltLight;
    line-height: 21px;
    border-bottom: 1px solid $lightColor;
    padding-bottom: 6px;
    // @include bigDesktop {
    //     line-height: 36px;
    //     padding-bottom: 16px;
    // }
    // @include laptop {
    //     line-height: 21px;
    //     padding-bottom: 6px;
    // }
    @include mobile {
        line-height: 18px;
        padding-bottom: 8px;
    }
}

.startLink {
    text-align: center;
    color: $greenColor;
    border-color: $greenColor;
}

@keyframes fadeInContent {
    from {
        transform: translateY(-50px);
        opacity: 0;
    } to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fadeInContentMobile {
    from {
        transform: translateY(-20px);
        opacity: 0;
    } to {
        transform: translateY(0);
        opacity: 1;
    }
}