@import 'src/styles/vars';

.button {
    cursor: pointer;
    height: 42px;
    line-height: 42px;
    padding: 0 18px;
    font-size: 18px;
    border-radius: 45px;
    color: $lightColor;
    border: 1px solid $greenColor;
    @include noMobile {
        &:hover {
            background: $greenColor;
            color: $grayColor;
        }
    }
    // @include bigDesktop {
    //     height: 84px;
    //     line-height: 84px;
    //     padding: 0 34px;
    //     font-size: 32px;
    // }
    // @include laptop {
    //     height: 42px;
    //     line-height: 42px;
    //     padding: 0 18px;
    //     font-size: 18px;
    // }
    @include mobile {
        width: 157px;
        height: 36px;
        line-height: 38px;
        font-size: 14px;
        text-align: center;
    }
}