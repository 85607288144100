@import 'src/styles/vars';
@import 'src/styles/flexbox';

.contacts {
    background-color: $grayColor6;
    padding: 85px 0 16px;
    // @include bigDesktop {
    //     padding: 170px 0 48px;
    // }
    // @include laptop {
    //     padding: 85px 0 16px;
    // }
    @include mobile {
        padding: 60px 0 12px;
    }
}

.content {
    @extend .box-col;
    row-gap: 32px;
    margin-top: 32px;
    // @include bigDesktop {
    //     row-gap: 48px;
    // }
    // @include laptop {
    //     margin-top: 32px;
    // }
    @include mobile {
        margin-top: 16px;
        row-gap: 24px;
    }
}

.main {
    @extend .box-row;
    @include mobile {
        column-gap: 16px;
    }
}

.contact {
    @extend .box-col;
    flex: 0 0 50%;
    row-gap: 11px;
    // @include bigDesktop {
    //     row-gap: 12px;
    // }
    // @include laptop {
    //     row-gap: 11px;
    // }
    @include mobile {
        flex: 0 0 calc(50% - 8px);
        row-gap: 8px;
    }
    @include noMobile {
        &:last-child {
            .label, .link {
                padding-left: 25px;
                // @include bigDesktop {
                //     padding-left: 40px;
                // }
                // @include laptop {
                //     padding-left: 25px;
                // }
            }
        }
    }
}

.label {
    line-height: 21px;
    color: $greenColor;
    font-family: $fontVisueltLight;
    // @include bigDesktop {
    //     line-height: 36px;
    // }
    // @include laptop {
    //     line-height: 21px;
    // }
    @include mobile {
        line-height: 18px;
    }
}

.link {
    color: white;
    font-size: 42px;
    line-height: 48px;
    text-transform: uppercase;
    border-bottom: 1px solid white;
    padding-bottom: 21px;
    // @include bigDesktop {
    //     font-size: 80px;
    //     line-height: 103px;
    //     padding-bottom: 23px;
    // }
    // @include laptop {
    //     font-size: 42px;
    //     line-height: 48px;
    //     padding-bottom: 21px;
    //     border-width: 1px;
    // }
    @include mobile {
        font-size: 20px;
        line-height: 28px;
        padding-bottom: 8px;
    }
}

.info {
    line-height: 21px;
    font-family: $fontVisueltLight;
    // @include bigDesktop {
    //     line-height: 36px;
    // }
    // @include laptop {
    //     line-height: 21px;
    // }
    @include mobile {
        line-height: 18px;
    }
}