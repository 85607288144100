@import 'src/styles/vars';

.button {
    height: 64px;
    line-height: 60px;
    font-size: 26px;
    color: $darkColor;
    border: 1px solid black;
    padding: 0 44px;
    font-family: $fontIosevka;
    // @include laptop {
    //     height: 64px;
    //     line-height: 60px;
    //     font-size: 26px;
    //     padding: 0 44px;
    //     border-width: 1px;
    // }
    @include mobile {
        height: 44px;
        line-height: 42px;
        font-size: 16px;
    }
}