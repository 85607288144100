html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
input,
textarea,
button {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  line-height: normal;
  vertical-align: baseline;
}

b {
  font-weight: bold;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*,
:after,
:before {
  box-sizing: border-box;
}

input[type='submit'],
input[type='reset'],
input[type='button'],
button {
  background: none;
  border: none;
  border-radius: 0;
  text-decoration: none;
  user-select: none;
  outline: none;
  -webkit-appearance: none;
  color: inherit;
  font: inherit;
  line-height: normal;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input,
textarea {
  -webkit-appearance: none;
  border-radius: 0;
  outline: none;
}

textarea {
  /* Turn off scroll bars in IE unless needed */
  overflow: auto;
}

a,
abbr,
p,
span {
  text-decoration: none;
}

input,
textarea,
a,
button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  height: 100%;
}

::-ms-clear {
  display: none;
}

::-webkit-search-decoration,
::-webkit-search-cancel-button,
::-webkit-search-results-button,
::-webkit-search-results-decoration {
  display: none;
}
