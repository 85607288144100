@import 'src/styles/vars';
@import 'src/styles/flexbox';

.principles {
    padding: 85px 0 32px;
    // @include bigDesktop {
    //     padding: 170px 0 87px;
    // }
    // @include laptop {
    //     padding: 85px 0 32px;
    // }
    @include mobile {
        padding: 60px 0 24px;
    }
}

.title {
    font-size: 42px;
    line-height: 58px;
    color: white;
    margin: 32px 0;
    // @include bigDesktop {
    //     font-size: 80px;
    //     line-height: 96px;
    //     margin: 64px 0 96px;
    // }
    // @include laptop {
    //     font-size: 42px;
    //     line-height: 58px;
    //     margin: 32px 0;
    // }
    @include mobile {
        font-size: 20px;
        line-height: 28px;
        margin: 16px 0;
    }
}

.list {
    margin-bottom: -21px;
    column-gap: 30px;
    @include noMobile {
        columns: 3;
    }
    // @include laptop {
    //     margin-bottom: -21px;
    //     column-gap: 35px;
    // }
    @include mobile {
        margin: 0;
        display: flex;
        flex-direction: column;
        row-gap: 16px;
    }
}

.item {
    @extend .box-col;
    row-gap: 6px;
    border-bottom: 1px solid $lightColor2;
    padding-bottom: 5px;
    margin-bottom: 21px;
    break-inside: avoid;
    line-height: 21px;
    // @include bigDesktop {
    //     line-height: 36px;
    // }
    // @include laptop {
    //     line-height: 21px;
    //     row-gap: 6px;
    //     padding-bottom: 5px;
    //     margin-bottom: 21px;
    // }
    @include mobile {
        margin: 0;
        row-gap: 8px;
        padding-bottom: 8px;
    }
}

.label {
    text-transform: uppercase;
    font-family: $fontVisueltMedium;
    line-height: 21px;
    // @include laptop {
    //     line-height: 21px;
    // }
    @include mobile {
        line-height: 18px;
    }
}

.desc {
    font-family: $fontVisueltLight;
    line-height: 21px;
    // @include bigDesktop {
    //     line-height: 36px;
    // }
    // @include laptop {
    //     line-height: 21px;
    // }
    @include mobile {
        line-height: 18px;
    }
}