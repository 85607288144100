.box-row,
.box-col {
    display: flex;
}

.box-row {
    flex-direction: row;
}

.box-col {
    flex-direction: column;
}

.box-wrap {
    flex-wrap: wrap;
}

.box-nowrap {
    flex-wrap: nowrap;
}

.space-between {
    justify-content: space-between;
}

.space-around {
    justify-content: space-around;
}

.space-center {
    justify-content: center;
}

.space-end {
    justify-content: flex-end;
}

.space-start {
    justify-content: flex-start;
}

.align-start {
    align-items: flex-start;
}

.align-center {
    align-items: center;
}

.align-end {
    align-items: flex-end;
}

.align-baseline {
    align-items: baseline;
}

.self-center {
    align-self: center;
}

.self-end {
    align-self: flex-end;
}

.self-start {
    align-self: flex-start;
}

.align-middle {
    justify-content: center;
    align-items: center;
}

.flex-auto {
    flex: 0 0 auto;
}

.flex-full {
    flex: 1 1 auto;
}