@import 'src/styles/vars';
@import 'src/styles/flexbox';

.header {
  position: sticky;
  top: 0;
  // height: 128px;
  height: 85px;
  background-color: $darkColor;
  color: $lightColor;
  z-index: 10;
  // @include bigDesktop {
  //   height: 168px;
  // }
  @include laptop {
    height: 85px;
  }
  @include mobile {
    height: 61px;
    background-color: $grayColor;
  }
}

.wrapper {
  @extend .box-row;
  @extend .align-center;
  @extend .space-between;
  height: 100%;
}

.content {
  @extend .box-row;
  @extend .align-center;
  @extend .flex-full;
  column-gap: 30px;
  padding-right: 20px;
}

.logo {
  @extend .box-row;
  img {
    width: 148px;
  }
  // @include bigDesktop {
  //   img {
  //     width: 272px;
  //   }
  // }
  // @include laptop {
  //   img {
  //     width: 148px;
  //   }
  // }
  @include mobile {
    width: 59px;
  }
}

.menu {
  @extend .box-row;
  @extend .flex-full;
  // @extend .space-center;
  column-gap: 20px;
  container-type: inline-size;
  // @include laptop {
  //   column-gap: 16px;
  // }
}

.navLink {
  color: $lightColor;
  line-height: 24px;
  font-size: 18px;
  // font-size: clamp(18px, 3cqi, 28px);
  // @include bigDesktop {
  //   line-height: 41px;
  // }
  // @include laptop {
  //   line-height: 24px;
  //   font-size: 18px;
  //   font-size: clamp(15px, 3cqi, 28px);
  // }
}

.messengers {
  @extend .box-row;
  column-gap: 20px;
  a {
    @extend .box-row;
  }
}

.userActions {
  @extend .box-row;
  @extend .flex-auto;
  column-gap: 14px;
  // @include bigDesktop {
  //   column-gap: 26px;
  // }
  // @include laptop {
  //   column-gap: 14px;
  // }
}

.burger {
  width: 22px;
  height: 18px;
  @extend .box-col;
  @extend .space-between;
  position: relative;
  span, &:before, &:after {
    content: '';
    height: 2px;
    width: 100%;
    background-color: white;
    border-radius: 2px;
    transition: all 200ms;
  }
  &.active {
    &:before, &:after {
      position: absolute;
      width: 26px;
    }
    &:before {
      transform: rotate(45deg);
      top: 8px;
      left: -3px;
    }
    &:after {
      transform: rotate(-45deg);
      top: 8px;
      left: -3px;
    }
  }
}