@import 'src/styles/vars';
@import 'src/styles/flexbox';

.sider.sider {
    position: fixed;
    left: 0;
    top: 61px;
    width: 100%;
    height: calc(100vh - 61px);
    padding: 0 24px;
    z-index: 10;
    background-color: $grayColor6;
    @extend .box-col;
    overflow-y: auto;
    animation-duration: 100ms;
}

.menu {
    @extend .box-col;
    @extend .flex-full;
    @extend .align-middle;
    padding: 50px 0 20px;
    row-gap: 24px;
    border-bottom: 1px solid $lightColor2;
}

.navLink {
    font-size: 18px;
    line-height: 24px;
    color: white;
}

.userActions {
    @extend .box-col;
    @extend .flex-auto;
    @extend .align-center;
    row-gap: 16px;
    padding: 44px 0 104px;
}

.login {
    background-color: $greenColor;
    color: $grayColor;
}

.reg {
    background-color: $grayColor;
    color: $greenColor;
}

.messengers {
    @extend .box-row;
    column-gap: 7px;
}