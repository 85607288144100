@import 'src/styles/vars';
@import 'src/styles/flexbox';

.footer {
    min-width: 375px;
    background-color: $darkColor;
    padding: 38px 0;
    @extend .flex-auto;
    // @include bigDesktop {
    //     padding: 70px 0;
    // }
    // @include laptop {
    //     padding: 38px 0;
    // }
    @include mobile {
        padding: 24px 0;
        background-color: $grayColor;
    }
}

.content {
    column-gap: 64px;
    margin-bottom: -10px;
    @include noMobile {
        columns: 2;
    }
    @include bigDesktop {
        column-gap: 80px;
    }
}

.text {
    display: block;
    line-height: 21px;
    font-family: $fontVisueltLight;
    margin-bottom: 10px;
    break-inside: avoid;
    // @include bigDesktop {
    //     line-height: 36px;
    // }
    // @include laptop {
    //     line-height: 21px;
    // }
    @include mobile {
        font-size: 12px;
        line-height: 15px;
    }
}