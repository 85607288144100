@import 'src/styles/vars';
@import 'src/styles/flexbox';

.docs {
    background-color: $grayColor6;
    padding: 16px 0 55px;
    // @include bigDesktop {
    //     padding: 48px 0 126px;
    // }
    // @include laptop {
    //     padding: 16px 0 55px;
    // }
    @include mobile {
        padding: 12px 0 32px;
    }
}

.list {
    margin-top: 32px;
    @extend .box-col;
    @extend .box-wrap;
    max-height: 74px;
    row-gap: 5px;
    column-gap: 32px;
    // @include bigDesktop {
    //     margin-top: 49px;
    //     max-height: 126px;
    //     column-gap: 40px;
    // }
    // @include laptop {
    //     margin-top: 32px;
    //     max-height: 74px;
    //     row-gap: 5px;
    // }
    @include mobile {
        margin-top: 16px;
        flex-wrap: nowrap;
        max-height: none;
    }
}

.link {
    line-height: 21px;
    font-family: $fontVisueltLight;
    color: $lightColor;
    &:not(:hover) {
        text-decoration: underline;
    }
    @include noMobile {
        width: 50%;
    }
    // @include bigDesktop {
    //     line-height: 36px;
    //     width: calc(50% - 20px);
    // }
    // @include laptop {
    //     line-height: 21px;
    // }
    @include mobile {
        line-height: 18px;
    }
}