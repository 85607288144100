@import 'src/styles/reset';
@import 'src/styles/flexbox';
@import 'src/styles/fonts';
@import 'src/styles/libs';
@import 'src/styles/vars';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
  min-height: 100%;
  scroll-behavior: smooth;
}

body {
  font: 16px $fontVisuelt, Tahoma, sans-serif;
  color: $lightColor;
  height: 100%;
  min-height: 100vh;
  line-height: 1;
  overflow-x: hidden;
  // @include bigDesktop {
  //   font-size: 28px;
  // }
  // @include laptop {
  //   font-size: 16px;
  // }
  @include mobile {
    font-size: 14px;
    &.noScroll {
      overflow: hidden;
    }
  }
}

#root {
  height: 100%;
}

.async-hide {
  opacity: 0 !important;
}

.layout-body {
  min-height: 100vh;
  @extend .box-col;
  background-color: $grayColor;
}

.content-center {
  height: 100%;
  margin: 0 auto;
  position: relative;
  box-sizing: content-box;
  padding: 0 20px;
  max-width: 1116px;
  // box-shadow: inset 20px 0 0 rgba(red, .1), inset -20px 0 0 rgba(red, .1);
  @include bigDesktop {
    // padding: 0 234px;
    // max-width: 2032px;
    // box-shadow: inset 234px 0 0 rgba(green, .05), inset -234px 0 0 rgba(green, .05);
    // box-shadow: inset 20px 0 0 rgba(green, .1), inset -20px 0 0 rgba(green, .1);
  }
  @include laptop {
    // padding: 0 82px;
    // box-shadow: inset 82px 0 0 rgba(orange, .1), inset -82px 0 0 rgba(orange, .1);
  }
  @include mobile {
    padding: 0 24px;
    // box-shadow: inset 24px 0 0 rgba(blue, .1), inset -24px 0 0 rgba(blue, .1);
  }
}