@font-face {
    font-family: 'Visuelt Pro';
    src: local('Visuelt Pro Regular'), local('Visuelt-Pro--Regular'),
        url('../fonts/VisueltPro-Regular.woff2') format('woff2'),
        url('../fonts/VisueltPro-Regular.woff') format('woff'),
        url('../fonts/VisueltPro-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Visuelt Pro Light';
    src: local('Visuelt Pro Light'), local('Visuelt-Pro--Light'),
        url('../fonts/VisueltPro-Light.woff2') format('woff2'),
        url('../fonts/VisueltPro-Light.woff') format('woff'),
        url('../fonts/VisueltPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Visuelt Pro Medium';
    src: local('Visuelt Pro Medium'), local('Visuelt-Pro--Medium'),
        url('../fonts/VisueltPro-Medium.woff2') format('woff2'),
        url('../fonts/VisueltPro-Medium.woff') format('woff'),
        url('../fonts/VisueltPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
	font-family: 'Iosevka Term SS17 Web';
	font-display: swap;
	font-weight: 400;
	font-stretch: normal;
	font-style: normal;
	src: url('../fonts/iosevka-term-ss17-regular.ttf') format('truetype'), url('../fonts/iosevka-term-ss17-regular.woff2') format('woff2');
}