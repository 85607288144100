@import 'src/styles/vars';
@import 'src/styles/flexbox';

.possibilities {
    padding: 85px 0 64px;
    // @include bigDesktop {
    //     padding: 170px 0 246px;
    // }
    // @include laptop {
    //     padding: 85px 0 64px;
    // }
    @include mobile {
        padding: 60px 24px;
    }
}

.top {
    margin-bottom: 32px;
    // @include bigDesktop {
    //     margin-bottom: 104px;
    // }
    // @include laptop {
    //     margin-bottom: 32px;
    // }
    @include mobile {
        margin: 0 0 16px -24px;
    }
}

:global(.swiper-horizontal.swiper-horizontal) {
    padding-right: 48px;
    @include mobile {
        padding-right: 0;
    }
}

.slide.slide {
    height: auto;
    width: 686px;
    // @include bigDesktop {
    //     width: 1248px;
    // }
    // @include laptop {
    //     width: 686px;
    // }
    @include mobile {
        width: auto;
    }
    &:first-child {
        // margin-left: 82px;
        margin-left: calc(50vw - 558px);

        // @include bigDesktop {
        //     margin-left: 234px;
        // }
        // @include laptop {
        //     margin-left: 82px;
        // }
        @include mobile {
            margin-left: 0;
        }
    }
}

.slideItem {
    border: 1px solid $grayColor4;
    padding: 45px 64px;
    height: 100%;
    // @include bigDesktop {
    //     padding: 88px 124px 115px;
    // }
    // @include laptop {
    //     padding: 45px 64px;
    //     border-width: 1px;
    // }
    @include mobile {
        padding: 17px 18px 20px;
        border-width: 2px;
    }
}

.slideTop {
    font-family: $fontVisueltLight;
    font-size: 46px;
    line-height: 54px;
    padding-bottom: 22px;
    border-bottom: 1px solid $grayColor5;
    // @include bigDesktop {
    //     font-size: 84px;
    //     padding-bottom: 60px;
    // }
    // @include laptop {
    //     font-size: 46px;
    //     line-height: 54px;
    //     padding-bottom: 22px;
    //     border-width: 1px;
    // }
    @include mobile {
        font-size: 24px;
        line-height: 28px;
        padding-bottom: 10px;
    }
    svg {
        margin: 0 10px;
        vertical-align: sub;
        width: 55px;
        height: auto;
        // @include laptop {
        //     margin: 0 10px;
        //     width: 55px;
        //     height: auto;
        // }
        @include mobile {
            margin: 0 5px;
            width: 33px;
        }
    }
}

.stronger {
    font-family: $fontVisueltMedium;
    background-color: $greenColor2;
    padding: 0 10px;
    // @include bigDesktop {
    //     padding: 0 20px;
    // }
    // @include laptop {
    //     padding: 0 10px;
    // }
    @include mobile {
        padding: 2px 5px;
    }
}

.slideList {
    @extend .box-col;
    padding-top: 22px;
    font-family: $fontVisueltLight;
    row-gap: 11px;
    // @include bigDesktop {
    //     padding-top: 41px;
    //     row-gap: 32px;
    // }
    // @include laptop {
    //     padding-top: 22px;
    //     row-gap: 11px;
    // }
    @include mobile {
        padding-top: 15px;
        row-gap: 8px;
    }
}

.item {
    line-height: 21px;
    position: relative;
    margin-left: 15px;
    // @include bigDesktop {
    //     line-height: 36px;
    //     margin-left: 0;
    // }
    // @include laptop {
    //     line-height: 21px;
    // }
    @include mobile {
        line-height: 18px;
        margin-left: 10px;
    }
    &::before {
        content: '';
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: $greenColor;
        left: -21px;
        top: 7px;
        // @include bigDesktop {
        //     width: 8px;
        //     height: 8px;
        //     left: -36px;
        //     top: 13px;
        // }
        // @include laptop {
        //     width: 5px;
        //     height: 5px;
        //     left: -21px;
        //     top: 7px;
        // }
        @include mobile {
            width: 2px;
            height: 2px;
            left: -6px;
        }
    }
}

.pager.pager.pager {
    margin-top: 64px;
    @extend .box-row;
    @extend .align-center;
    @extend .space-center;
    column-gap: 10px;
    // @include bigDesktop {
    //     margin-top: 116px;
    // }
    // @include laptop {
    //     margin-top: 64px;
    // }
    @include mobile {
        margin-top: 45px;
        column-gap: 2px;
    }
    :global(.swiper-pagination-bullet) {
        height: 6px;
        width: 94px;
        border-radius: 0;
        opacity: 1;
        background: $grayColor6;
        margin: 0;
        // @include bigDesktop {
        //     width: 116px;
        // }
        @include mobile {
            width: 38px;
            height: 3px;
        }
    }
    :global(.swiper-pagination-bullet.swiper-pagination-bullet-active) {
        background: $greenColor;
        height: 12px;
        @include mobile {
            height: 6px;
        }
    }
}